import PropTypes from 'prop-types'
import { useEffect, useState, useLayoutEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { resetState, setField } from '../../../reducers/nineHoleEventReducer'
import { addStudent, deleteStudent, addCourse, deleteCourse, updateStudent, updateCourse } from '../../../reducers/nineHoleEventReducer'
import Axios from '../../../axios'
import moment from 'moment'

import * as Constants from '../../../misc/Constants'
import * as commonActions from '../../../actions/commonActions'

import PageHeader from '../../common/PageHeader/PageHeader'
import PageFooter from './PageFooter'
import PreviewEventModal from '../Modal/PreviewEventModal'
import ConfirmationModal from '../Modal/ConfirmationModal'
import ExitModal from '../Modal/ExitModal'
import ManageRoster from './ManageRoster'
import LogScores from './LogScores'
import PostScores from './PostScores'
import EventSubmitted from '../Modal/EventSubmitted'
import Loader from '../../common/Op36Loader-web'

import styles from './EventForm.module.scss'

const HeaderText = {
  1: 'Manage Roster',
  2: 'Log Scores',
  3: 'Post Scores',
}

const errorFields = ['missingScore', 'scoreLessThan9', 'invalidYardage', 'invalidFullTeeYardage', 'invalidGreens', 'invalidPutts']
const yardageValues = ['225', '450', '900', '1350', '1800', 'fullTee']

const _isValidFullTeeYardage = (fullTee, yardage) => {
  if (fullTee !== 'fullTee') {
    return true
  }
  return parseInt(yardage, 10) > 1800
}

const EventForm = ({ // eslint-disable-line max-lines-per-function
  onClickBack,
  isEdit,
  isQuickLog,
  createEvent,
  updateEvent,
  handleEventSubmittedClick,
  updateEnrollments,
  eventLoader,
  studentsLoader,
}) => {
  const dispatch = useDispatch()

  const event = useSelector(state => state.nineHoleEvent)
  const currentUser = useSelector(state => state.user.current_user)
  const errors = useSelector(state => state.current_user)

  const [formStep, setFormStep] = useState(1)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showExitModal, setShowExitModal] = useState(false)
  const [eventScores, setEventScores] = useState(null)
  const [scoresSaved, setScoresSaved] = useState(false)
  const filteredStudents = event.students.filter(s => s.status !== 'declined')

  const getScores = useCallback(() => {
    const scores = JSON.parse(localStorage.getItem('draftScores'))
    setEventScores(scores && scores[event.id])
    setScoresSaved(scores && scores[event.id] ? true : false)
  }, [event.id])

  useEffect(() => {
    getScores()
  }, [event.id, getScores])

  useLayoutEffect(() => {
    dispatch(commonActions.showSideBar(false))
    dispatch(commonActions.showNavBar(false))
  }, [dispatch])

  useEffect(() => {
    const action = event.action
    if (action === 'Log Scores' || action === 'Edit Results') {
      setFormStep(2)
    }
  }, [dispatch, event.action])

  useEffect(() => {
    dispatch(setField({ field: 'creator_id', value: currentUser.id }))
    dispatch(setField({ field: 'play_type_id', value: Constants.PLAY_TYPE.OP36_ID }))
  }, [dispatch, currentUser.id])

  const next = () => {
    setFormStep(value => value + 1)
  }

  const confirmExit = () => {
    dispatch(resetState())
    onClickBack()
  }

  const cancelExit = () => {
    setShowExitModal(false)
  }

  const previous = () => {
    if (formStep === 1 || (event.action === 'Log Scores' && formStep <= 2 )) {
      setShowExitModal(true)
    } else {
      setFormStep(value => value - 1)
    }
  }

  const validateEvent = () => {
    const error = {}
    if (!event.name) {
      error.name = ['Please enter the name!']
    }
    if (!event.location) {
      error.location = ['Please enter the location!']
    }
    if (!event.event_date) {
      error.event_date = ['Please select a date!']
    }

    dispatch(setField({ field: 'errors', value: error }))

    return Object.keys(error).length === 0
  }

  const validateStudents = () => {
    const studentErrors = []
    let students = event.students

    if (!isQuickLog) {
      students = filteredStudents
    }

    if (new Date(event.event_date) > new Date().setUTCHours(23, 59, 59, 999)) {
      studentErrors.push('Unable to log scores because the event is in future!')
    } else {
      students.forEach((student) => {
        const yardage = '' + student.yardage
        const studentName = `${student.first_name} ${student.last_name}`

        const errors = {
          missingScore: !student.score,
          scoreLessThan9: parseInt(student.score, 10) < 9,
          invalidYardage: !yardageValues.includes(yardage),
          invalidFullTeeYardage: !_isValidFullTeeYardage(student.yardage, student.fullTeeYardage),
          invalidGreens: student.greens !== '' && parseInt(student.greens, 10) < 0,
          invalidPutts: student.putts !== '' && parseInt(student.putts, 10) < 0,
        }

        const errorMessages = {
          missingScore: `Student ${studentName} should have a score.`,
          scoreLessThan9: `Student ${studentName} should have a score bigger or equal to 9.`,
          invalidYardage: `Student ${studentName}'s yardage should be one of the following: ${yardageValues.join(', ')}.`,
          invalidFullTeeYardage: `Full tee yardage value for student ${studentName} should be bigger than 1800.`,
          invalidGreens: `Greens for ${studentName} should be a positive number.`,
          invalidPutts: `Putts for ${studentName} should be a positive number.`,
        }

        errorFields.forEach((err) => {
          if (errors[err]) {
            studentErrors.push(errorMessages[err])
          }
        })
      })
    }

    dispatch(setField({ field: 'errors', value: { ...errors, students: studentErrors } }))

    if (studentErrors.length === 0) {
      next()
    }
  }

  const togglePreviewModal = useCallback(() => {
    setShowPreviewModal(!showPreviewModal)
    dispatch(commonActions.showSideBar(false))
    dispatch(commonActions.showNavBar(false))
  }, [dispatch, setShowPreviewModal, showPreviewModal])

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const goBackToEdit = () => {
    togglePreviewModal()
    previous()
  }

  const handlePreviewSubmit = () => {
    if (validateEvent()) {
      setShowConfirmationModal(true)
    }
    setShowPreviewModal(false)
  }

  const handleSubmit = () => {
    if (isEdit) {
      updateEvent()
    } else {
      createEvent()
    }
    next()

    setShowConfirmationModal(false)
  }

  const saveDraftScores = useCallback(() => {
    const draftScores = JSON.parse(localStorage.getItem('draftScores'))
    const scores = {}
    event.students.forEach(({ user_id, first_name, last_name, score, greens, putts, yardage, fullTeeYardage, level }) => {
      scores[user_id] = { first_name, last_name, score, greens, putts, yardage, fullTeeYardage, level }
    })
    const savedAt = moment(new Date()).format('YYYY-MM-DD HH:mm')
    localStorage.setItem('draftScores', JSON.stringify({ ...draftScores, [event.id]: { scores, savedAt } }))
    Axios.post(`/api/v1/events/${event.id}/draft_scores`, { scores, community_id: currentUser.communities[0].id })
    setScoresSaved(true)
    getScores()
  }, [event, getScores, currentUser.communities])

  const saveDraftAndExit = () => {
    saveDraftScores()
    confirmExit()
  }

  return (
    eventLoader
      ? (<Loader message={event.id === -1 ? 'Creating Event' : 'Updating Event'}/>)
      : studentsLoader
        ? (<Loader message='Loading students'/>)
        : (
          <div className={styles.eventForm}>
            <div className='fixed-top'>
              <PageHeader
                title='9 Hole Events'
                middleText={event.action === 'Edit Roster' ? `${event.series.title}, ${event.indexName} Roster` : HeaderText[formStep]}
                backButton
                onClickBack={() => event.action === 'Edit Roster' ? onClickBack() : setShowExitModal(true)}
              />
            </div>

            {formStep === 1 &&
              <ManageRoster
                title='Populate Roster'
                selectedStudents={event.students}
                getSelectedStudent={(id) => event.students.find(student => student.id === id)}
                getSelectedProgram={(id) => event.courses.find(course => course.id === id)}
                addStudentCallback={addStudent}
                deleteStudentCallback={deleteStudent}
                addProgramCallback={addCourse}
                deleteProgramCallback={deleteCourse}
                updateStudentCallback={updateStudent}
                updateProgramCallback={updateCourse}
              />
            }

            {formStep === 2 && (
              <LogScores
                handleAddGolfer={() => setFormStep(1)}
                setScoresSaved={setScoresSaved}
                isQuickLog={isQuickLog}
              />
            )}
            {formStep === 3 && <PostScores status={event.status}/>}
            {formStep === 4 && <EventSubmitted isEdit={isEdit} onClick={handleEventSubmittedClick}/>}

            <PageFooter
              event={event}
              step={formStep}
              next={next}
              saveScores={saveDraftScores}
              scoresSaved={scoresSaved}
              eventScores={eventScores}
              updateEnrollments={updateEnrollments}
              validateStudents={validateStudents}
              updateEvent={updateEvent}
              togglePreviewModal={togglePreviewModal}
              showSaveScores={filteredStudents.length > 0}
            />

            <PreviewEventModal
              showModal={showPreviewModal}
              closeModal={togglePreviewModal}
              onSubmit={handlePreviewSubmit}
              goBackToEdit={goBackToEdit}
            />

            <ConfirmationModal
              showModal={showConfirmationModal}
              closeModal={toggleConfirmationModal}
              onSubmit={handleSubmit}
              isEdit={event.status === 'completed'}
            />

            <ExitModal
              showModal={showExitModal}
              closeModal={cancelExit}
              onSubmit={confirmExit}
              saveDraftScores={saveDraftAndExit}
              event={event}
            />
          </div>
        )
  )
}

EventForm.propTypes = {
  onClickBack: PropTypes.func,
  isEdit: PropTypes.bool,
  isQuickLog: PropTypes.bool,
  createEvent: PropTypes.func,
  updateEvent: PropTypes.func,
  updateEnrollments: PropTypes.func,
  handleEventSubmittedClick: PropTypes.func,
  eventLoader: PropTypes.bool,
  studentsLoader: PropTypes.bool,
}

export default EventForm
