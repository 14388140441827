import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { getStudentsDualPanelSelector } from '../actions/usersActions'
import { getProgramsDualPanelSelectorThunk } from '../actions/programActions'
import { isEmpty } from 'lodash'

const studentsEntityAdapter = createEntityAdapter()
const unavailableStudentsEntityAdapter = createEntityAdapter()
const programsEntityAdapter = createEntityAdapter()

const initialState = {
  students: {
    isLoading: true,
    searchValue: '',
    items: studentsEntityAdapter.getInitialState(),
    unavailableItems: unavailableStudentsEntityAdapter.getInitialState(),
    pagination: { page: 0, isLastPage: false },
  },
  programs: {
    isLoading: true,
    searchValue: '',
    items: programsEntityAdapter.getInitialState(),
    pagination: { page: 0, isLastPage: false },
  },
}

const dualPanelSelectorReducer = createSlice({
  name: 'dualPanelSelector',
  initialState,
  reducers: {
    setUnavailableStudents: (state, action) => {
      unavailableStudentsEntityAdapter.setAll(state.students.unavailableItems, action.payload)
    },
    setSearchValue: (state, action) => {
      const key = action.payload.key
      if (state[key]) {
        state[key].searchValue = action.payload.searchValue
      }
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder
      // Get students
      .addCase(getStudentsDualPanelSelector.pending, state => {
        state.isLoading = true
      })
      .addCase(getStudentsDualPanelSelector.fulfilled, (state, action) => {
        const pagination = action.payload.meta.pagination
        const students = action.payload.students
        if (isEmpty(pagination) || pagination.page === 1) {
          studentsEntityAdapter.setAll(state.students.items, students)
        } else {
          studentsEntityAdapter.addMany(state.students.items, students)
        }
        state.isLoading = false
        state.students.pagination = pagination
      })

      // Get programs
      .addCase(getProgramsDualPanelSelectorThunk.pending, state => {
        state.isLoading = true
      })
      .addCase(getProgramsDualPanelSelectorThunk.fulfilled, (state, action) => {
        const pagination = action.payload.meta.pagination
        const programs = action.payload.courses
        if (isEmpty(pagination) || pagination.page === 1) {
          programsEntityAdapter.setAll(state.programs.items, programs)
        } else {
          programsEntityAdapter.addMany(state.programs.items, programs)
        }
        state.programs.pagination = action.payload.meta.pagination
        state.isLoading = false
      })
  },
})

const {
  selectAll: selectAllStudents,
} = studentsEntityAdapter.getSelectors(state => state.dualPanelSelector.students.items)

export const selectStudentsData = createSelector(
  state => state.dualPanelSelector.students,
  selectAllStudents,
  (studentsData, students) => ({
    ...studentsData,
    items: students,
  })
)

const {
  selectAll: selectAllPrograms,
} = programsEntityAdapter.getSelectors(state => state.dualPanelSelector.programs.items)

export const selectProgramsData = createSelector(
  state => state.dualPanelSelector.programs,
  selectAllPrograms,
  (programsData, programs) => ({
    ...programsData,
    items: programs,
  })
)

export const {
  setUnavailableStudents,
  setSearchValue,
  reset,
} = dualPanelSelectorReducer.actions

export default dualPanelSelectorReducer.reducer
