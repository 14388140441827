import { getDashboardProgram } from '../actions/programActions'
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { addEnrollmentManually, deleteEnrollment, updateEnrollment } from '../actions/enrollmentActions'
import { getShowProgramSchedule } from '../actions/scheduleActions'
import { deleteScheduleSession, updateScheduleSession } from '../actions/scheduleSessionActions'
import { defaultScheduleSessionCompareFunction, programRosterUserEnrollmentCompareFunction } from '../utility/helperFunctions'

const selectedStudentsEntityAdapter = createEntityAdapter()
const selectedProgramsEntityAdapter = createEntityAdapter()
const enrollmentsEntityAdapter = createEntityAdapter()

const initialState = {
  program: null,
  enrollments: enrollmentsEntityAdapter.getInitialState(),
  schedule: null,
  isLoading: false,
  isLoadingScheduleTab: true,
  addStudentsModal: {
    selectedStudents: selectedStudentsEntityAdapter.getInitialState(),
    selectedPrograms: selectedProgramsEntityAdapter.getInitialState(),
  },
}

const programDashboardReducer = createSlice({
  name: 'programDashboard',
  initialState,
  reducers: {
    resetDashboard: () => initialState,
    resetSelectedData: state => {
      selectedStudentsEntityAdapter.setAll(state.addStudentsModal.selectedStudents, [])
      selectedProgramsEntityAdapter.setAll(state.addStudentsModal.selectedPrograms, [])
    },
    addSelectedStudent: (state, action) => {
      selectedStudentsEntityAdapter.addOne(state.addStudentsModal.selectedStudents, action.payload)
    },
    updateSelectedStudent: (state, action) => {
      selectedStudentsEntityAdapter.setOne(state.addStudentsModal.selectedStudents, action.payload)
    },
    deleteSelectedStudent: (state, action) => {
      selectedStudentsEntityAdapter.removeOne(state.addStudentsModal.selectedStudents, action.payload)
    },
    addSelectedProgram: (state, action) => {
      selectedProgramsEntityAdapter.addOne(state.addStudentsModal.selectedPrograms, action.payload)
    },
    deleteSelectedProgram: (state, action) => {
      selectedProgramsEntityAdapter.removeOne(state.addStudentsModal.selectedPrograms, action.payload)
    },
  },
  extraReducers: builder => {
    builder
      // Get Program Overview Data
      .addCase(getDashboardProgram.pending, state => {
        state.isLoading = true
      })
      .addCase(getDashboardProgram.fulfilled, (state, action) => {
        state.program = action.payload.course
        enrollmentsEntityAdapter.setAll(state.enrollments, action.payload.course.enrollments)
        state.isLoading = false
      })

      // Add student manually
      .addCase(addEnrollmentManually.fulfilled, (state, action) => {
        const enrollment = action.payload.enrollment

        if (state.program) {
          state.program.golferLimit = enrollment.course.golferLimit
          enrollmentsEntityAdapter.addOne(state.enrollments, enrollment)
        }
      })

      // Update enrollment
      .addCase(updateEnrollment.fulfilled, (state, action) => {
        const enrollment = action.payload.enrollment

        if (state.program) {
          enrollmentsEntityAdapter.setOne(state.enrollments, enrollment)
        }
      })

      // Delete enrollment
      .addCase(deleteEnrollment.fulfilled, (state, action) => {
        const enrollmentId = action.payload.enrollment.id

        if (state.program) {
          enrollmentsEntityAdapter.removeOne(state.enrollments, enrollmentId)
        }
      })

      // Get Schedule Tab data
      .addCase(getShowProgramSchedule.pending, state => {
        state.isLoadingScheduleTab = true
      })
      .addCase(getShowProgramSchedule.fulfilled, (state, action) => {
        state.schedule = action.payload.schedule
        state.isLoadingScheduleTab = false
      })

      // Update single Schedule Session
      .addCase(updateScheduleSession.fulfilled, (state, action) => {
        if (state.schedule) {
          const index = state.schedule.scheduleSessions.findIndex(session => session.id === action.payload.scheduleSession.id)
          state.schedule.scheduleSessions[index] = action.payload.scheduleSession
          state.schedule.scheduleSessions.sort(defaultScheduleSessionCompareFunction)
        }
      })

      // Delete Single Schedule Session
      .addCase(deleteScheduleSession.fulfilled, (state, action) => {
        if (state.schedule) {
          const index = state.schedule.scheduleSessions.findIndex(session => session.id === action.payload.scheduleSession.id)
          state.schedule.scheduleSessions.splice(index, 1)
        }
      })
  },
})

export const {
  resetDashboard,
  resetSelectedData,
  addSelectedProgram,
  addSelectedStudent,
  updateSelectedStudent,
  deleteSelectedProgram,
  deleteSelectedStudent,
} = programDashboardReducer.actions

export const {
  selectAll: selectSelectedStudents,
} = selectedStudentsEntityAdapter.getSelectors(state => state.programDashboard.addStudentsModal.selectedStudents)

export const {
  selectAll: selectSelectedPrograms,
} = selectedProgramsEntityAdapter.getSelectors(state => state.programDashboard.addStudentsModal.selectedPrograms)

export const {
  selectAll: selectAllEnrollments,
} = enrollmentsEntityAdapter.getSelectors(state => state.programDashboard.enrollments)

export const selectAllEnrollmentsRosterData = createSelector(
  selectAllEnrollments,
  enrollments => enrollments.toSorted(programRosterUserEnrollmentCompareFunction).map(e => ({ enrollment: e }))
)

export const selectEnrolledStudents = createSelector(
  selectAllEnrollments,
  enrollments => enrollments?.map(e => e.user)
)

export default programDashboardReducer.reducer
