import { createAsyncThunk } from '@reduxjs/toolkit'
import { deserialize, serialize } from '../utility/serialization'

import Axios from '../axios'
import { handleAPIError } from '../utility/actionHelper'

// THUNKS

export const getEditProgram = createAsyncThunk(
  'program/getEditProgram',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetProgram(programId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getDashboardProgram = createAsyncThunk(
  'program/getDashboardProgram',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetProgram(programId)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getSignUpPageProgram = createAsyncThunk(
  'program/getPresentationProgram',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestGetSignUpPageProgram(programId)

      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getEventRosterProgram = async (id) => {
  try {
    const { data } = await requestGetEventRosterProgram(id)

    return deserialize(data)
  } catch (error) {
    return handleAPIError(error)
  }
}

export const getDashboardPrograms = createAsyncThunk(
  'program/getDashboardPrograms',
  async (filters, { rejectWithValue }) => {
    try {
      const { data } = await requestGetPrograms(filters)

      return { ...deserialize(data), filters }
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCoachPrograms = createAsyncThunk(
  'program/getCoachPrograms',
  async ({ filters, page, perPage, withEnrollments }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetPrograms(filters, page, perPage, withEnrollments)
      return { ...deserialize(data), filters }
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getProgramsScheduleSummary = createAsyncThunk(
  'program/getProgramsScheduleSummary',
  async ({ filters, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetProgramsScheduleSummary(filters, page, perPage)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCommunityLandingPagePrograms = createAsyncThunk(
  'program/getCommunityLandingPagePrograms',
  async ({ communityId, filters, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCommunityLandingPagePrograms(communityId, filters, page, perPage)
      return { ...deserialize(data), filters }
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getCategoryPrograms = createAsyncThunk(
  'program/getCategoryPrograms',
  async ({ communityId, categoryId, page, perPage }, { rejectWithValue }) => {
    try {
      const { data } = await requestGetCommunityLandingPagePrograms(communityId, { categoryId }, page, perPage)
      return { ...deserialize(data), categoryId }
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const createProgram = createAsyncThunk(
  'program/createProgram',
  async (programData, { rejectWithValue }) => {
    try {
      const { data } = await requestCreateProgram(programData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const updateProgram = createAsyncThunk(
  'program/updateProgram',
  async (programData, { rejectWithValue }) => {
    try {
      const { data } = await requestUpdateProgram(programData)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const deleteProgram = createAsyncThunk(
  'program/deleteProgram',
  async (programId, { rejectWithValue }) => {
    try {
      const { data } = await requestDeleteProgram(programId)
      return deserialize({ data, programId })
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getProgramsFilterOptions = createAsyncThunk(
  'program/getProgramsFilterOptions',
  async(filters, { rejectWithValue }) => {
    try {
      const { data } = await requestGetProgramsFilterOptions(filters)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

export const getProgramsDualPanelSelectorThunk = createAsyncThunk(
  'program/getProgramsDualPanelSelector',
  async ({ searchTerm, page, perPage, withPackages, completed }, { rejectWithValue }) => {
    const filters = {
      search: searchTerm,
      withPackages,
      completed,
      ...(completed ? { order: ['end_date DESC', 'start_date DESC'] } : null),
    }

    try {
      const { data } = await requestGetPrograms(filters, page, perPage, true)
      return deserialize(data)
    } catch (error) {
      return handleAPIError(error, rejectWithValue)
    }
  }
)

// TODO: remove after using the dualPanelSelector state everywhere
export const getProgramsDualPanelSelector = async ({ searchTerm, page, perPage, withPackages }) => {
  const filters = {
    search: searchTerm,
    withPackages,
  }

  try {
    const { data } = await requestGetPrograms(filters, page, perPage, true)
    return deserialize(data)
  } catch (error) {
    return handleAPIError(error)
  }
}

// API CALLS

function requestGetPrograms(filters, page, perPage, withEnrollments) {
  const requestUrl = '/api/v1/courses'
  const params = serialize({ ...filters, page, perPage, withEnrollments })

  return Axios.get(requestUrl, { params })
}

function requestGetProgram(programId, mode) {
  const requestUrl = '/api/v1/courses/' + programId
  const params = { mode }

  return Axios.get(requestUrl, { params })
}

const requestGetSignUpPageProgram = (id) => Axios.get(`/api/v1/courses/${id}/sign_up_page`)

const requestGetEventRosterProgram = (id) => Axios.get(`/api/v1/courses/${id}/event_roster`)

function requestGetProgramsScheduleSummary(filters, page, perPage) {
  const requestUrl = '/api/v1/courses/schedule_summary'
  const params = serialize({ ...filters, page, perPage })

  return Axios.get(requestUrl, { params })
}

const requestGetCommunityLandingPagePrograms = (communityId, filters, page, perPage) => {
  const requestUrl = '/api/v1/courses/community_landing_page'
  const params = serialize({ ...filters, communityId, page, perPage })

  return Axios.get(requestUrl, { params })
}

function requestCreateProgram(programData) {
  const requestUrl = '/api/v1/courses/'

  const data = new FormData()
  const serializedProgramData = serialize(programData)
  Object.keys(serializedProgramData).forEach((key) => data.append(key, serializedProgramData[key]))

  return Axios.post(requestUrl, data)
}

function requestUpdateProgram(programData) {
  const requestUrl = `/api/v1/courses/${programData.id}`

  const data = new FormData()
  const serializedProgramData = serialize(programData)
  Object.keys(serializedProgramData).forEach((key) => data.append(key, serializedProgramData[key]))

  return Axios.put(requestUrl, data)
}

function requestDeleteProgram(programId) {
  const requestUrl = `/api/v1/courses/${programId}`
  return Axios.delete(requestUrl)
}

function requestGetProgramsFilterOptions(filters) {
  const requestUrl = '/api/v1/courses/filter_options'
  const params = serialize(filters)

  return Axios.get(requestUrl, { params })
}

export const requestGetProgramEnrollmentsSummary = (id) => Axios.get(`/api/v1/courses/${id}/enrollments_summary`)
