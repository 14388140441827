import { useDispatch, useSelector } from 'react-redux'
import Activity from '../../activity/Activity'
import { ReactComponent as PlaceholderIcon } from '../../../assets/images/common/icons/chat-boxes-icon.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/images/common/icons/Rectangle.svg'
import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { getCommunityFeedActivities } from '../../../actions/dashboardActions'
import PropTypes from 'prop-types'
import { clearActivities } from '../../../reducers/dashboardReducer.js'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'
import ActivitySkeleton from '../../activity/ActivitySkeleton.js'

import styles from './CommunityFeed.module.scss'

const PER_PAGE = 6

const CommunityFeed = ({ communityId }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [page, setPage] = useState(0)
  const { feedActivities, activitiesTotalPages } = useSelector(state => state.dashboard)
  const dispatch = useDispatch()

  const fetchActivities = () => {
    dispatch(getCommunityFeedActivities({ communityId, page: page + 1, perPage: PER_PAGE })).unwrap()
      .then(() => setPage((prevState) => prevState + 1))
  }

  useEffect(() => {
    fetchActivities()
    if (window.innerWidth < 1200){
      setCollapsed(true)
    }
    return () => {
      dispatch(clearActivities())
    }
  }, [])

  const isFirstPageLoading = page === 0 && feedActivities.length === 0

  return (
    <div
      className={classNames(styles.communityFeed, { [styles.communityFeedCollapsed]: collapsed })}
      onClick={() => collapsed && setCollapsed(false)}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => { e.key === 'Enter' && collapsed && setCollapsed(false) }}
    >
      <div className={classNames(styles.feedHeader, { [styles.feedHeaderCollapsed]: collapsed })}>
        {collapsed ? (
          <button className={styles.iconWrapper} onClick={() => setCollapsed(false)}>
            <ArrowIcon/>
          </button>
        ) : (
          <>
            <p className={`${styles.feedTitle} col-10`}>Community Feed</p>
            <div className={styles.arrowWrapper}>
              <button className={styles.arrowButton} onClick={() => setCollapsed(true)}/>
            </div>
          </>
        )}
      </div>
      {!collapsed && (isFirstPageLoading ? (
        <div className={classNames('d-flex flex-column align-items-center', styles.feedContainer, styles.loadingSection)}>
          <ActivitySkeleton />
          <ActivitySkeleton />
          <ActivitySkeleton />
        </div>
      ) : feedActivities.length === 0 ? (
        <div className={classNames('d-flex flex-column align-items-center', styles.feedContainer)}>
          <div className={styles.text}>
            This is your Op 36 Programs Community Feed. Every activity logged by your students will be shared here.
            Be sure to like and comment to engage your golfers!
          </div>
          <PlaceholderIcon className='mt-5' />
          <div className={styles.placeholderTitle}>No Recent Activity</div>
          <div className={styles.placeholderText}>
            Create Programs, 9 Hole Events, Log Playing and Training sessions!
          </div>
        </div>
      ) : (
        <div className={styles.feedContainer} id='feedContainer'>
          <InfiniteScroll
            dataLength={feedActivities.length}
            next={fetchActivities}
            hasMore={page < activitiesTotalPages}
            loaderClassName={styles.loader}
            scrollableTarget='feedContainer'
          >
            {feedActivities.map((activity) =>
              <Activity
                key={activity.id}
                displayComments={false}
                activity={activity}
                isStudentJourney
              />
            )}
          </InfiniteScroll>
        </div>
      ))}
    </div>
  )
}

CommunityFeed.propTypes = {
  communityId: PropTypes.number,
}

export default CommunityFeed
