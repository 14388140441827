import { createSlice, createSelector } from '@reduxjs/toolkit'
import { deleteEnrollment, getEventDetail } from '../actions/eventActions'
import { updateSeriesEnrollment } from '../actions/seriesEnrollmentActions'
import { getLevel, yardageByLevel } from '../utility/curriculumHelper'

const initialState = {
  id: -1,
  selectedEvent: {},
  eventLoader: false,
  deleteLoader: false,
  playTypeLoader: true,
  students: [],
  courses: [],
  enrollments: [],
  holes: 9,
  play_type_id: -1,
  description: '',
  event_date: new Date(),
  location: '',
  name: '',
  isEmail: true,
  uploadPhotoName: '',
  uploadPhotoFile: '',
  uploadPhotoSrc: '',
  indexName: '',
  series: null,
  creator_id: -1,
  isStudentError: false,
  isEventError: false,
  status: null,
  errors: {},
}

const nineHoleEventReducer = createSlice({
  name: 'nineHoleEvent',
  initialState,
  reducers: {
    resetState: () => initialState,
    setField: (state, action) => {
      const { field, value } = action.payload
      state[field] = value
    },

    updateFields: (state, action) => {
      const fields = action.payload
      return {
        ...state,
        ...fields,
      }
    },

    addStudent: (state, action) => {
      const std = action.payload
      state.students.push(std)
    },

    updateStudent: (state, action) => {
      const student = action.payload
      const newStudents = [...state.students]
      const index = newStudents.findIndex(s => s.user_id === student.user_id)
      newStudents[index] = student
      state.students = newStudents
    },

    deleteStudent: (state, action) => {
      const stdId = action.payload
      const index = state.students.findIndex(s => s.user_id === stdId)

      state.students.splice(index, 1)
    },
    addCourse: (state, action) => {
      const course = { ...action.payload, selectedPackages: [] }
      state.courses.push(course)
    },
    updateCourse: (state, action) => {
      const course = action.payload
      const newCourses = [...state.courses]
      const index = newCourses.findIndex(c => c.id === course.id)
      newCourses[index] = course
      state.courses = newCourses
    },
    deleteCourse: (state, action) => {
      const courseId = action.payload
      const index = state.courses.findIndex(c => c.id === courseId)

      state.courses.splice(index, 1)
    },
  },
  extraReducers: {
    // Delete Enrollment
    [deleteEnrollment.fulfilled]: (state, action) => {
      const filteredStudents = state.students.filter(std => std.user_id !== action.payload.seriesEnrollment.userId)
      state.students = filteredStudents
    },
    [deleteEnrollment.rejected]: (state, action) => {
      state.errors = action.payload
    },

    //UpdateEnrollment
    [updateSeriesEnrollment.fulfilled]: (state, action) => {
      const index = state.students.findIndex(std => std.user_id === action.payload.seriesEnrollment.userId)
      state.students[index].status = action.payload.seriesEnrollment.status
    },
    [updateSeriesEnrollment.rejected]: (state, action) => {
      state.errors = action.payload
    },

    // Get Event
    [getEventDetail.fulfilled]: (state, action) => {
      const event = action.payload.event
      const activities = event.activities
      state.id = event.id
      state.name = event.name
      state.event_date = event.event_date
      state.location = event.location
      state.description = event.description
      state.status = event.status
      state.uploadPhotoSrc = event.photo
      state.indexName = event.index_name
      state.nextRsvp = event.next_rsvp
      state.daysUntilEvent = event.days_until_event
      state.confirmedEnrollmentsCount = event.confirmed_enrollments_count
      state.respondedEnrollmentsCount = event.responded_enrollments_count
      state.series = event.series
      state.activityEvent = event.activity_event

      state.students = []
      if (!event.series) {
        activities?.forEach((activity) => {
          if (activity.type === 'ActivityPlay') {
            const student = activity.user
            if (student) {

              state.students.push({
                user_id: student.id,
                current_formal_division: student.current_formal_division,
                milestone: activity.milestone,
                level: getLevel(activity.yardage),
                first_name: student.first_name,
                last_name: student.last_name,
                stats: student.stats,
                score: activity.score,
                greens: activity.greens || '',
                putts: activity.putts || '',
                yardage: activity.is_full_tee ? 'fullTee' : activity.yardage,
                fullTeeYardage: activity.is_full_tee ? activity.yardage : '',
                avatar: student.avatar,
                age: student.age,
                gender: student.gender,
                familyAdminsEmails: student.family_admins_emails,
              })
            }
          }
        })


      } else {
        event?.enrollments?.forEach((e) => {
          const student = e.student
          let addedFrom = null
          if (e.course) {
            if (e.package) {
              addedFrom = { [e.course.id]: { [e.package.id]: { packageName: e.package.name, courseName: e.course.name } } }
            } else {
              addedFrom = { [e.course.id]: e.course.name }
            }
          } else {
            addedFrom = { students: true }
          }

          let studentData = {
            id: student.id,
            user_id: student.id,
            current_formal_division: student.current_formal_division,
            yardage: yardageByLevel[student.current_formal_division],
            level: student.current_formal_division,
            firstName: student.first_name,
            first_name: student.first_name,
            lastName: student.last_name,
            last_name: student.last_name,
            email: student.email,
            username: student.username,
            stats: student.stats,
            avatar: student.avatar,
            age: student.age,
            gender: student.gender,
            familyAdminsEmails: student.family_admins_emails,
            courseName: e.course_name,
            addedFrom: addedFrom,
            status: e.status,
            enrollmentId: e.id,
            note: e.note,
            birthdate: student.birthdate,
          }

          const activity = activities.find((a) => a.user_id === student.id)
          if (activity) {
            studentData = {
              ...studentData,
              score: activity.score,
              greens: activity.greens || '',
              putts: activity.putts || '',
              yardage: activity.is_full_tee ? 'fullTee' : activity.yardage,
              fullTeeYardage: activity.is_full_tee ? activity.yardage : '',
              milestone: activity.milestone,
              level: getLevel(activity.yardage),
            }
          }
          state.students.push(studentData)
        })
      }
    },
    [getEventDetail.rejected]: (state, action) => {
      state.errors = action.payload
    },
  },
})

export const {
  resetState,
  setField,
  updateFields,
  addStudent,
  updateStudent,
  deleteStudent,
  addCourse,
  updateCourse,
  deleteCourse,
} = nineHoleEventReducer.actions

export const eventFormData = createSelector(
  state => state.nineHoleEvent,
  eventFields => ({
    id: eventFields.id === -1 ? null : eventFields.id,
    name: eventFields.name,
    isEmail: eventFields.isEmail,
    description: eventFields.description,
    location: eventFields.location,
    creatorId: eventFields.creator_id,
    playTypeId: eventFields.play_type_id,
    eventDate: eventFields.event_date,
    photoAttributes: eventFields.uploadPhotoFile
      ? { photo: eventFields.uploadPhotoFile, name: eventFields.uploadPhotoFile.name }
      : !eventFields.uploadPhotoSrc ? { _destroy: true } : null,
    activities: eventFields.students?.length > 0
      ? eventFields.students.filter(s => s.status !== 'declined').map((student) => ({
        userId: student.user_id,
        yardage: student.yardage,
        fullTeeYardage: student.fullTeeYardage || '',
        greens: student.greens || '',
        putts: student.putts || '',
        addedFrom: student.addedFrom,
        score: student.score,
      }))
      : [],
  })
)

export default nineHoleEventReducer.reducer
