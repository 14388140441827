import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { calculateAge, genderLabel } from '../../../utility/userHelper'

import StudentWithAvatar from '../../students/StudentWithAvatar'
import SentReminderModal from '../Modal/SentReminderModal'
import RosterLabels from './RosterLabels'
import * as eventActions from '../../../actions/eventActions'
import { UncontrolledPopover } from 'reactstrap'
import colors from '../../../assets/styles/globals.scss'
import styles from './GuestList.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import { ReactComponent as MenuIcon } from '../../../assets/images/common/icons/more-icon-vertical.svg'
import { ReactComponent as MessageIcon } from '../../../assets/images/common/icons/Message.svg'
import classNames from 'classnames'
import SeriesEnrollmentStatusSelect from './SeriesEnrollmentStatusSelect'
import Tooltip from '../../common/Tooltip'

const GuestList = ({ event, removeStudent }) => {
  const dispatch = useDispatch()
  const students = event.students
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [showSentReminderModal, setShowSentReminderModal] = useState(false)

  const handleStudentMenuClick = (student) => {
    setSelectedStudent(student)
  }

  const sendRSVP = () => {
    dispatch(eventActions.sendEventReminder({ eventId: event.id, userId: selectedStudent.user_id })).unwrap()
      .then(() => setShowSentReminderModal(true))
      .catch(() => {
        toast.error(`The rsvp reminder for "${selectedStudent.first_name + ' ' + selectedStudent.last_name}" could not be sent.`,
          { position: toast.POSITION.BOTTOM_LEFT })
      })
  }

  return (
    <div id='LogScores' className='d-flex flex-column table-container'>
      <RosterLabels event={event}/>
      <div className={classNames('d-flex flex-column', styles.enrollmentsList)}>
        <Tooltip/>
        {students?.length > 0 ?
          <table className='table table-responsive animated fadeIn w-100'>
            <thead>
              <tr>
                <th className='golfer-header'>Golfer</th>
                <th className='px-5'>Age, Gender</th>
                <th>Program</th>
                <th>RSVP</th>
                <th>Note</th>
                <th className='centered-text'>Record Score</th>
                <th className='centered-text'>Level Attempts</th>
                <th className='px-5 centered-text'>{event.status !== 'completed' && 'Actions'}</th>
              </tr>
            </thead>
            <tbody>
              { students.map((std, index) =>
                <tr
                  key={index}
                  className={'students-table-row'}
                >
                  <td style={{ width: '25%' }} className='golfer-data'>
                    <StudentWithAvatar student={std} withName />
                  </td>
                  <td style={{ width: '22%' }} className='px-5'>{calculateAge(std.birthdate)} yrs, {genderLabel[std.gender]}</td>
                  <td style={{ width: '20%' }}>{std.courseName || 'Manually Added'}</td>
                  <td style={{ width: '13%' }}><SeriesEnrollmentStatusSelect isDisabled={event.status === 'completed'} enrollment={std}/></td>
                  <td className='centered-text'>{std.note &&
                    <MessageIcon data-tip={std.note} data-tip-disable={false} className={styles.messageIcon}/>}
                  </td>
                  <td style={{ width: '10%' }} className='centered-text'>{std.stats && std.stats[std.level].record_score || '-'}</td>
                  <td style={{ width: '10%' }} className='centered-text'>{std.stats && std.stats[std.level].level_attempts || '-'}</td>
                  <td className='centered-text'>
                    <div className={styles.deleteButton} onClick={() => handleStudentMenuClick(std)} aria-hidden='true' id='studentMenuButton'>
                      <MenuIcon fill={colors.primaryNavy} hidden={event.status === 'completed'}/>
                    </div>
                  </td>
                </tr>
              )}

              <UncontrolledPopover
                placement='bottom'
                target='studentMenuButton'
                trigger='legacy'
              >
                <div className={classNames('d-flex flex-column', styles.studentActions)}>
                  <p aria-hidden='true' onClick={() => removeStudent(selectedStudent.enrollmentId)}>Remove Golfer</p>
                  <p aria-hidden='true' onClick={sendRSVP}>Send RSVP Reminder</p>
                </div>
              </UncontrolledPopover>
            </tbody>
          </table>
          :
          <div className={styles.noStudentsPlaceholder}>
            <GolferIcon id='golferIcon' fill={colors.lightNavy} width={'64px'} height={'64px'}/>
            <p className={styles.placeholderTitle}>No Students Enrolled</p>
            <div className={styles.placeholderDescription}>There are no enrollments for this event.</div>
          </div>
        }
      </div>
      <SentReminderModal
        showModal={showSentReminderModal}
        closeModal={() => setShowSentReminderModal(false)}
      />
    </div>
  )
}

GuestList.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number,
    students: PropTypes.array,
    status: PropTypes.string,
  }),
  removeStudent: PropTypes.func.isRequired,
}

export default GuestList
