import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatDates } from '../../../utility/datesHelper'
import { colorNameToData, getColorNameFromStatus } from '../../../utility/program-dashboard-helper'
import { selectAllEnrollments } from '../../../reducers/programDashboardReducer'
import { Link, useNavigate } from 'react-router-dom'
import DefaultMultiActionsPopover from '../../common/PageHeader/DefaultMultiActionsPopover'
import EnrollmentsProgressBar from '../enrollments-details/EnrollmentsProgressBar'

import classes from './Header.module.scss'
import { ReactComponent as OutsideLinkIcon } from '../../../assets/images/common/icons/outside-link-icon.svg'
import { ReactComponent as LeftArrow } from '../../../assets/images/common/icons/left-arrow.svg'

const Header = ({ isAddStudentsModalOpen, isLoadingDeleteProgram, handleOpenAddStudentsModal, handleDeleteProgramClick, withAddStudentsAction }) => {
  const navigate = useNavigate()

  const timezone = useSelector(state => state.user.current_user.communities[0].timezone)
  const { program } = useSelector(state => state.programDashboard)
  const enrollments = useSelector(selectAllEnrollments)

  const colorName = useMemo(() => getColorNameFromStatus(program), [program])

  return (
    <header
      style={{
        background: program.headerImage
          ? `linear-gradient(${colorNameToData[colorName].header.color}, ${colorNameToData[colorName].header.color}), ` +
          `url(${program?.headerImage?.originalFileUrl}) center/cover no-repeat`
          : colorNameToData[colorName].header.color,
      }}
      className={classnames('pb-4 pl-4 pr-5 d-flex justify-content-between', classes.header)}
    >
      <div className='d-flex flex-column'>
        <div className='mb-3 d-flex'>
          <button className={classnames('animation-shrink align-items-center', classes.backButton)} onClick={() => navigate('/programs')}>
            <LeftArrow />
          </button>
          <h1 className={classnames('mb-0 ml-3', classes.title)}>{program.name}</h1>
        </div>
        <span className={classes.dates}>{formatDates(program.startDate, program.endDate, timezone)}</span>
        <span className={classes.meetingTimes}>{program.meetingTimes}</span>
      </div>
      <div className='d-flex flex-column align-items-end justify-content-between'>
        <DefaultMultiActionsPopover
          isModalOpen={isAddStudentsModalOpen}
          title='Manage Program'
          actions={[
            { label: 'Edit Program', link: true, to: `/programs/${program.id}/edit` },
            { label: 'Edit Schedule', link: true, to: `/programs/${program.id}/edit?step=2` },
            ...(withAddStudentsAction
              ? [{ label: 'Add Students', onClick: handleOpenAddStudentsModal }]
              : []
            ),
            { label: 'Delete', color: 'red', onClick: handleDeleteProgramClick, isLoading: isLoadingDeleteProgram },
          ]}
        />
        <div className='mt-2 d-flex align-items-end'>
          <EnrollmentsProgressBar program={program} enrollments={enrollments} classNames={{ golfersCount: classes.golfersCount }} />
          <Link
            target='_blank'
            to={`/landing/${program.communityId}/class/${program.id}`}
            className='ml-3 h-fit-content d-flex align-items-center'
          >
            <OutsideLinkIcon className='mr-2' />
            View Sign-Up Page
          </Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isAddStudentsModalOpen: PropTypes.bool,
  handleOpenAddStudentsModal: PropTypes.func,
  handleDeleteProgramClick: PropTypes.func,
  isLoadingDeleteProgram: PropTypes.bool,
  withAddStudentsAction: PropTypes.bool,
}

export default Header
