import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modal from '../../common/Modal'
import SubmitButton from '../../common/buttons/SubmitButton'

import classes from './ConfirmationModal.module.scss'
import { ReactComponent as GolferIcon } from '../../../assets/images/common/icons/golfer-half-icon.svg'
import mobileLeaderboard from '../../../assets/images/mock/mobile-leaderboard.webp'


const ConfirmationModal = ({ showModal, closeModal, onSubmit, isEdit }) => {

  const createEventText = 'Are you sure you want to submit\
                          \nscores, the following will happen:\
                          \n\n  1. Your Golfers will be emailed, if you\
                          \n       selected so\
                          \n\n  2. Scores will show up in the mobile\
                          \n       app.'

  const updateEventText = 'Are you sure you want to update\
                          \nscores, the following will happen:\
                          \n\n  1. The leaderboard will be updated\
                          \n\n  2. No one will receive any updates that\
                          \n       the changes have been made.'

  return (
    <Modal
      isOpen={showModal}
      onClose={closeModal}
      contentLabel='Program Builder Live Modal'
      className={classes['modal']}
      autoFocus
    >
      <div className={classes['wrapper']}>
        <div className='d-flex'>
          <div className={classes['text-content']}>
            <div className={classnames('d-flex align-items-center', classes['title-container'])}>
              <div className={classnames('d-flex justify-content-center align-items-center', classes['icon-wrapper'])}>
                <GolferIcon className={classes['golfer-icon']} fill='#fff' width={'30px'} height={'30px'} />
              </div>
              <span className={classes['title']}>{isEdit ? 'Update' : 'Submit'} Scores</span>
            </div>
            <div className={classes['text-container']}>{isEdit ? updateEventText : createEventText}</div>
          </div>
          <div className={classnames('d-flex justify-content-end', classes['image'])}>
            <img className={classes['mobile-leaderboard']} src={mobileLeaderboard} alt='mobile leaderboard' />
          </div>
        </div>
        <SubmitButton
          onClick={onSubmit}
          buttonMessage={isEdit ? 'Update Scores' : 'Publish Scores'}
          buttonColor='orange-button'
        />
      </div>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
}

export default ConfirmationModal
