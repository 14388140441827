import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { requiredIf } from '../../../utility/prop-types-helper'
import classnames from 'classnames'
import { debounce } from 'lodash'
import SearchInput from '../SearchInput'
import InfiniteScroll from '../../infinite-scroll/InfiniteScroll'
import ItemCard from './items/ItemCard'
import Loader from '../Op36Loader-web'

import classes from './DualPanelSelector.module.scss'
import { DUAL_PANEL_SELECTABLE_TYPES } from '../../../constants/dual-panel-selector-constants'
import StateSwitcher from '../StateSwitcher'

const SEARCH_PLACEHOLDER = {
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'Search for Active Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'Search for Active Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.students]: 'Search for Students',
}

const SEARCH_PLACEHOLDER_COMPLETED = {
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'Search for Completed Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'Search for Completed Programs',
}

const LIST_PLACEHOLDER_TITLE = {
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'No Programs Available',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'No Programs Available',
  [DUAL_PANEL_SELECTABLE_TYPES.students]: 'No Students Available',
}

const LIST_LOADER_TEXT = {
  [DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages]: 'Loading Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.programs]: 'Loading Programs',
  [DUAL_PANEL_SELECTABLE_TYPES.students]: 'Loading Students',
}

const COMPLETED_SWITCHER_OPTIONS = [
  { type: false, text: 'Active' },
  { type: true, text: 'Completed' },
]

const Selector = ({
  className,
  type,
  titleText,
  allItems,
  withCompletedSwitcher,
  searchValue,
  onSearchValueChange,
  getSelectedItem,
  fetchItems,
  pagination,
  onCheckChange,
  onSelectChange,
  onSelectDelete,
  itemCategoryTitle,
  itemCategoryCompare,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [completed, setCompleted] = useState(false)

  const withAdditionalSelect = type === DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages

  const fetchItemsInitial = useCallback((searchTerm, completed = false) => {
    setIsLoading(true)
    fetchItems(1, searchTerm, completed)
      .then(() => setIsLoading(false))
  }, [fetchItems])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(searchValue => {
      fetchItemsInitial(searchValue, completed)
    }, 400),
    [fetchItemsInitial, completed])

  const handleSearchChange = (e) => {
    const value = e.target.value
    onSearchValueChange(value)
    debouncedSearch(value)
  }

  const handleCompletedStateSwitch = (value) => {
    setCompleted(value)
    fetchItemsInitial(searchValue, value)
  }

  useEffect(() => {
    if ((!allItems || allItems.length === 0) && pagination.page === 0) {
      fetchItemsInitial('')
    }
  }, [allItems, fetchItemsInitial, pagination.page])

  return (
    <div className={classnames('d-flex flex-column', classes.selector, className)}>
      {!!titleText && <h3 className={classes.title}>{titleText}</h3>}
      {withCompletedSwitcher && (
        <StateSwitcher
          options={COMPLETED_SWITCHER_OPTIONS}
          selectedType={completed}
          initialTypeSelected={false}
          stateSwitcher={handleCompletedStateSwitch}
          position='left'
          rounded
          color='orange'
        />
      )}
      {searchValue !== undefined && (
        <SearchInput
          className={classes.searchBar}
          placeholder={withCompletedSwitcher && completed ? SEARCH_PLACEHOLDER_COMPLETED[type] : SEARCH_PLACEHOLDER[type]}
          value={searchValue}
          onChange={handleSearchChange}
        />
      )}
      <hr className={classes.hr} />
      {(!allItems || allItems.length === 0) && pagination.page === 0 || isLoading ? (
        <Loader message={LIST_LOADER_TEXT[type]} />
      ) : (allItems.length > 0 ? (
        <div id='infinite-scroll-wrapper' className={classnames('pb-1', classes.listWrapper)}>
          <InfiniteScroll
            className={classes.list}
            dataLength={allItems.length}
            next={() => fetchItems(pagination.page + 1, searchValue)}
            hasMore={!pagination.isLastPage}
            scrollableTarget='infinite-scroll-wrapper'
          >
            {allItems.map((item, index, items) => {
              const selectedItem = getSelectedItem(item.id)
              const displayedItem = selectedItem ?? item

              return (
                <ItemCard
                  key={displayedItem.id}
                  type={type}
                  item={displayedItem}
                  hasNewCategory={!completed && !!itemCategoryCompare && (index === 0 || itemCategoryCompare(displayedItem, items[index - 1]) > 0)}
                  categoryTitle={!completed && !!itemCategoryTitle && itemCategoryTitle(displayedItem)}
                  isSelectable
                  isChecked={!!selectedItem}
                  withAdditionalSelect={withAdditionalSelect}
                  onCheckChange={onCheckChange}
                  onSelect={onSelectChange}
                  onSelectDelete={onSelectDelete}
                />
              )
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={classnames('d-flex flex-column justify-content-center align-items-center', classes.placeholder)}>
          <h4 className={classes.title}>{searchValue ? `No results for ${searchValue}` : LIST_PLACEHOLDER_TITLE[type]}</h4>
          <p className={classes.text}>{searchValue ? 'Please check spelling and try again.' : 'Please try again.'}</p>
        </div>
      ))}
    </div>
  )
}

export const TabProps = {
  titleText: PropTypes.string,
  allItems: PropTypes.array,
  withCompletedSwitcher: PropTypes.bool,
  searchValue: PropTypes.string,
  onSearchValueChange: requiredIf(PropTypes.func, ({ searchValue }) => searchValue !== undefined),
  getSelectedItem: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  onCheckChange: PropTypes.func.isRequired,
  onSelectChange: requiredIf(PropTypes.func, ({ type }) => type === DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages),
  onSelectDelete: requiredIf(PropTypes.func, ({ type }) => type === DUAL_PANEL_SELECTABLE_TYPES.programsWithPackages),
  itemCategoryTitle: PropTypes.func,
  itemCategoryCompare: requiredIf(PropTypes.func, ({ itemCategoryKey }) => !!itemCategoryKey),
}

Selector.propTypes = {
  ...TabProps,
  type: PropTypes.oneOf(Object.values(DUAL_PANEL_SELECTABLE_TYPES)).isRequired,
}

export default Selector
