import StatsPanel from './StatsPanel'
import ActivityFeed from './ActivityFeed'

const OverviewTab = () => (
  <div className='h-100 d-flex'>
    <ActivityFeed />
    <StatsPanel />
  </div>
)

export default OverviewTab
