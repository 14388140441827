import StockProfilePic from '../../../assets/images/mock/StockProfilePic.png'
import itemStyles from '../../../assets/styles/components/common/SideBarItem.module.scss'
import styles from '../../../assets/styles/components/common/ExpandableSideBar.module.scss'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import { NAVIGATION } from '../../../misc/Constants'
import { useSelector } from 'react-redux'

const SideBarProfile = () => {
  const user = useSelector(state => state.user.current_user)
  const navigation = useSelector (state => state.ui.sideBarState)
  const collapsed = navigation === NAVIGATION.COLLAPSED
  const isAdmin = user.type === 'Admin'

  return (
    <><div className={styles.profileWrapper}>
      <Link to={isAdmin ? '/account' : `/profile/${user.id}`} data-for='profileTooltip' data-tip>
        <div
          className={collapsed ? styles.profileImg : `${styles.profileImg} ${styles.profileImgExpanded}`}
          style={{
            backgroundImage: user.avatar
              ? `url(${user.avatar.file_url})`
              : `url(${StockProfilePic})`,
          }}
        ></div>
      </Link>
      {!collapsed &&
        <div className={styles.profileTooltip}>
          {!isAdmin && <div><Link to={`/profile/${user.id}`}>{`${user.first_name} ${user.last_name}`}</Link></div>}
          <div><Link to='/account?editTab=personal'>Edit Account</Link></div>
          <div><Link to='/log-in' className={styles.logOut}>Log Out</Link></div>
        </div>}
    </div>

    <ReactTooltip id='profileTooltip'
      effect='solid'
      delayUpdate={100}
      delayHide={100}
      place={'right'}
      className={collapsed ? itemStyles.navTooltip : styles.expandedNoTooltip}
    >
      <div className={styles.profileTooltip}>
        {!isAdmin && <div><Link to={`/profile/${user.id}`}>View Profile</Link></div>}
        <div><Link to='/account?editTab=personal'>Edit Account</Link></div>
        <div><Link to='/log-in' className={styles.logOut}>Log Out</Link></div>
      </div>
    </ReactTooltip></>

  )
}

export default SideBarProfile
