import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestGetProgramActivities } from '../../../../actions/activityActions'
import { toast } from 'react-toastify'

import InfiniteScroll from '../../../infinite-scroll/InfiniteScroll'
import Activity from '../../../activity/Activity'
import ActivitySkeleton from '../../../activity/ActivitySkeleton'

import styles from './ActivityFeed.module.scss'
import { ReactComponent as PlaceholderIcon } from '../../../../assets/images/common/icons/chat-boxes-icon.svg'
import { ReactComponent as ArrowIcon } from '../../../../assets/images/common/icons/Rectangle.svg'

const PER_PAGE = 6
const paginationInitialState = { page: 0, isLastPage: null }

const ActivityFeed = () => {
  const { program } = useSelector(state => state.programDashboard)

  const [collapsed, setCollapsed] = useState(false)
  const [pagination, setPagination] = useState(paginationInitialState)
  const [showJustAnnouncements, setShowJustAnnouncements] = useState(false)
  const [activities, setActivities] = useState([])

  const fetchActivities = () => {
    const type = showJustAnnouncements && 'announcement'
    requestGetProgramActivities(program.id, pagination.page + 1, PER_PAGE, type)
      .then(response => {
        const data = response.data
        const newPage = data.meta.pagination.page

        setActivities(prevState => ([
          ...prevState,
          ...data.activities,
        ]))
        setPagination({
          page: newPage,
          isLastPage: data.meta.pagination.is_last_page,
        })
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again.', { position: toast.POSITION.TOP_RIGHT })
        setPagination(prevState => ({
          ...prevState,
          page: prevState.page + 1,
        }))
      })
  }

  const handleSwitchButtonClick = () => {
    setShowJustAnnouncements(prevState => !prevState)
    setPagination(paginationInitialState)
    setActivities([])
  }

  useEffect(() => {
    fetchActivities()
    if (window.innerWidth < 1200) {
      setCollapsed(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showJustAnnouncements])

  const isFirstPageLoading = pagination.page === 0 && activities.length === 0

  return (
    <div
      className={classnames(styles.communityFeed, { [styles.collapsed]: collapsed })}
      onClick={() => collapsed && setCollapsed(false)}
      role='button'
      tabIndex={0}
      onKeyDown={(e) => {
        e.key === 'Enter' && collapsed && setCollapsed(false)
      }}
    >
      <div className={classnames(styles.feedHeader, { [styles.feedHeaderCollapsed]: collapsed })}>
        {collapsed ? (
          <button className={styles.iconWrapper} onClick={() => setCollapsed(false)}>
            <ArrowIcon />
          </button>
        ) : (
          <>
            <p className={`${styles.feedTitle} col-10`}>Activity Feed</p>
            <div className={styles.arrowWrapper}>
              <button className={styles.arrowButton} onClick={() => setCollapsed(true)} />
            </div>
          </>
        )}
      </div>
      {!collapsed && (
        <>
          <div className='w-100 p-3'>
            <button
              className={classnames('d-flex justify-content-center align-items-center', styles.switchButton)}
              onClick={handleSwitchButtonClick}
            >
              <div
                className={classnames(styles.switchOption, !showJustAnnouncements && styles.switchSelected)}
              >
                Feed
              </div>
              <div
                className={classnames(styles.switchOption, showJustAnnouncements && styles.switchSelected)}
              >
                Announcements
              </div>
            </button>
          </div>
          {isFirstPageLoading ? (
            <div className={classnames('d-flex flex-column align-items-center', styles.feedContainer, styles.loadingSection)}>
              <ActivitySkeleton />
              <ActivitySkeleton />
              <ActivitySkeleton />
            </div>
          ) : activities.length === 0 ? (
            <div className={classnames('d-flex flex-column align-items-center', styles.feedContainer)}>
              <div className={classnames('mt-3', styles.placeholderText)}>
                This is your Program’s Activity Feed. Every activity logged by your students will be shared here.&nbsp;
                Activity Feed will show activities from the start date to the end date of the program.&nbsp;
                Be sure to like and comment to engage your golfers!
              </div>
              <PlaceholderIcon className='mt-5 flex-shrink-0' />
              <div className={classnames('mt-3', styles.placeholderTitle)}>No Recent Activity</div>
            </div>
          ) : (
            <div className={styles.feedContainer} id='feedContainer'>
              <InfiniteScroll
                dataLength={activities.length}
                next={fetchActivities}
                hasMore={!pagination.isLastPage}
                loaderClassName={styles.loader}
                scrollableTarget='feedContainer'
              >
                {activities.map((activity) =>
                  <Activity
                    key={activity.id}
                    displayComments={false}
                    activity={activity}
                    isStudentJourney
                  />
                )}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  )
}

ActivityFeed.propTypes = {
  communityId: PropTypes.number,
}

export default ActivityFeed
