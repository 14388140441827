import React, { Component } from 'react'
import Modal from 'react-modal'
import _ from 'lodash'
import ReactPlayer from 'react-player'

// REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as commonActions from '../../../../actions/commonActions'
import * as educationActions from '../../../../actions/educationActions'

// CHILDREN
import EducationSidebar from './EducationSidebar'
import CompletedEducation from '../../CompletedEducation'

// ASSETS
import './EducationVideoModal.scss'
import PropTypes from 'prop-types'

const modalStyles = {
  content: {
    padding: '0px',
    backgroundColor: '#3d3d3d',
  },
}

class EducationVideoModal extends Component {
  state = {
    selectedLesson: _.isEmpty(this.props.selectedLesson)
      ? {}
      : this.props.selectedLesson,
    lessonVideo: 'https://www.youtube.com/watch?v=QJdYAk7H2Mw',
    showSidebar: true,
    hoverToggled: false,
    showInfoSection: false,
    completeLessonLoader: false,
    educationComplete: false,
    changeLesson: false,
  }

  componentDidMount() {
    if (window.$zopim && window.$zopim.livechat) { window.$zopim.livechat.hideAll() }
  }

  componentWillUnmount() {
    if (window.$zopim && window.$zopim.livechat) { window.$zopim.livechat.button.show() }
  }

  allLessonsComplete = () => {
    const { user_education, education } = this.props

    if (!user_education?.completed_lessons) { return false }

    const completedLessonCount = user_education.completed_lessons.length
    let lessonCount = 0

    education.education_sections.map(
      (section) => (lessonCount += section.education_lessons.length)
    )

    if (completedLessonCount === lessonCount) {
      return true
    }
    return false
  }

  completeLesson = () => {
    const { selectedLesson } = this.state
    const {
      education,
      education_actions,
      current_user,
      user_education,
    } = this.props

    this.setState({
      completeLessonLoader: true,
    })
    education_actions
      .completeLesson(selectedLesson, education, current_user)
      .then(() => {
        if (user_education.id) {
          education_actions.getUserEducation(user_education.id).then(() => {
            this.setState(
              { completeLessonLoader: false },
              () => {
                if (this.props.user_education.is_completed) {
                  this.setState({ educationComplete: true })
                } else {
                  this.nextLesson()
                }
              }
            )
          })
        }
      })
  }

  validateFirstLesson = () => {
    const { education } = this.props
    const { selectedLesson } = this.state

    const firstLesson = education.education_sections[0].education_lessons[0]

    if (firstLesson.id === selectedLesson.id) {
      return false
    }
    return true
  }

  previousLesson = () => {
    const { selectedLesson } = this.state
    const { education } = this.props
    this.setState({ changeLesson: true })
    const sectionIndex = education.education_sections
      .map((section) => section.id)
      .indexOf(selectedLesson.education_section_id)

    const lessonIndex = education.education_sections[
      sectionIndex
    ].education_lessons
      .map((lesson) => lesson.id)
      .indexOf(selectedLesson.id)

    let previousLesson =
      education.education_sections[sectionIndex].education_lessons[
        lessonIndex - 1
      ]

    if (!previousLesson) {
      previousLesson =
        education.education_sections[sectionIndex - 1].education_lessons[
          education.education_sections[sectionIndex - 1].education_lessons
            .length - 1
        ]
    }

    this.setState(
      {
        selectedLesson: previousLesson,
      },
      () => {
        this.setState({
          changeLesson: false,
        })
      }
    )
  }

  nextLesson = () => {
    const { selectedLesson } = this.state
    const { education, current_user } = this.props
    this.setState({ changeLesson: true })

    const sectionIndex = education.education_sections
      .map((section) => section.id)
      .indexOf(selectedLesson.education_section_id)

    const lessonIndex = education.education_sections[
      sectionIndex
    ].education_lessons
      .map((lesson) => lesson.id)
      .indexOf(selectedLesson.id)

    let nextLesson =
      education.education_sections[sectionIndex].education_lessons[
        lessonIndex + 1
      ]

    if (!nextLesson && sectionIndex < education.education_sections.length - 1) {
      nextLesson =
        education.education_sections[sectionIndex + 1].education_lessons[0]
    } else if (
      !nextLesson &&
      sectionIndex >= education.education_sections.length - 1
    ) {
      nextLesson =
        current_user.type === 'Admin'
          ? education.education_sections[0].education_lessons[0]
          : this.chooseFirstUncompletedLesson()
    }

    this.setState(
      {
        selectedLesson: nextLesson,
      },
      () => {
        this.setState({ changeLesson: false })
      }
    )
  }

  chooseFirstUncompletedLesson = () => {
    const { education, user_education } = this.props
    let firstUncompletedLesson

    if (!user_education?.completed_lessons?.length) { return education.education_sections[0].education_lessons[0] }

    for (let i = 0; i < education.education_sections.length; i++) {
      const section = education.education_sections[i]

      if (!firstUncompletedLesson) {
        for (let z = 0; z < section.education_lessons.length; z++) {
          const lesson = section.education_lessons[z]
          const completedIndex = user_education.completed_lessons
            .map((completedLesson) =>
              parseInt(Object.keys(completedLesson), 10)
            )
            .indexOf(lesson.id)
          if (completedIndex === -1) {
            firstUncompletedLesson = lesson
            break
          }
        }
      } else {
        break
      }
    }

    return firstUncompletedLesson
  }

  onMouseEnter = () => {
    clearTimeout(this.timeout)
    this.setState({
      hoverToggled: true,
    })
  }

  onMouseLeave = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        hoverToggled: false,
      })
    }, 2500)
  }

  createMarkup = () => ({ __html: this.state.selectedLesson.description })

  toggleSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    })
  }

  selectLesson = (selectedLesson) => {
    this.setState({ selectedLesson }, () => {
      this.setState({ lessonVideo: selectedLesson.video_url })
    })
  }

  renderCompletedView = () => {
    const { education, closeCompleteModal } = this.props

    return (
      <div className='education-completed-view'>
        <div className='col-6 mx-auto mt-5'>
          <CompletedEducation
            education={education}
            closeCompleteModal={closeCompleteModal}
          />
        </div>
      </div>
    )
  }
  renderContentView = () => {
    const { selectedLesson, completeLessonLoader, changeLesson } = this.state
    const { isCoach } = this.props

    if (!_.isEmpty(selectedLesson)) {
      return (
        <div className='education-content-view col-md-8 mx-auto'>
          <div className='education-video-header-title'>
            <h5 className='modal-title display-4'>
              {_.isEmpty(selectedLesson)
                ? 'Video Modal'
                : selectedLesson.name}
            </h5>
          </div>
          {selectedLesson.video_url && (
            <div className='player-wrapper'>
              {!changeLesson && (
                <ReactPlayer
                  url={selectedLesson.video_url}
                  width='100%'
                  height='100%'
                  className='react-player'
                  controls
                  playing
                  onEnded={
                    isCoach && !this.allLessonsComplete()
                      ? this.completeLesson
                      : this.nextLesson
                  }
                />
              )}
            </div>
          )}

          <div
            className={`${
              selectedLesson.description && selectedLesson.video_url
                ? 'education-video-footer'
                : 'education-video-footer-no-bg'
            } animated fadeIn`}
          >
            {this.validateFirstLesson() && (
              <button
                className='btn btn-dark'
                onClick={this.previousLesson}
                disabled={completeLessonLoader}
              >
                Previous Lesson
              </button>
            )}
            <button
              className='btn btn-primary'
              onClick={
                isCoach && !this.allLessonsComplete()
                  ? this.completeLesson
                  : this.nextLesson
              }
              disabled={completeLessonLoader}
            >
              {completeLessonLoader && (
                <i className='fa fa-spinner fa-spin loading-spinner' />
              )}
              {isCoach && !this.allLessonsComplete()
                ? 'Complete & Continue'
                : 'Next Lesson'}
            </button>
          </div>
          {selectedLesson.description &&
            selectedLesson.description.length > 10 && (
            <div className='education-info-header'>
              <p style={{ color: '#f68a22' }}>Lesson Overview</p>
            </div>
          )}
          <div
            className='education-lesson-description'
            dangerouslySetInnerHTML={this.createMarkup()}
          />
        </div>
      )
    }
    return null
  }

  render() {
    const {
      showModal,
      closeModal,
      education,
      isCoach,
      user_education,
    } = this.props
    const {
      showSidebar,
      selectedLesson,
      hoverToggled,
      educationComplete,
    } = this.state

    return (
      <Modal
        id='EducationVideoModal'
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel='Education Video Modal'
        style={modalStyles}
      >
        <div className='row education-video-content-container animated fadeIn'>
          {showSidebar && (
            <div className='col-md-3 p-0'>
              <EducationSidebar
                education={education}
                userEducation={user_education}
                selectLesson={this.selectLesson}
                selectedLesson={selectedLesson}
                isCoach={isCoach}
              />
            </div>
          )}
          <div
            className={`${
              showSidebar ? 'col-md-9 p-0' : 'col-md-12 p-0'
            } education-content-container`}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
          >
            <div
              className={`education-video-header row m-0 p-0 ${
                hoverToggled ? 'animated fadeIn' : 'animated fadeOut'
              }`}
            >
              <div className='education-video-header-title col-11 m-0 p-0' />
              <div className='education-close-button-wrapper col-1 m-0 p-0'>
                <button
                  type='button'
                  className='close pull-right font-200'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={closeModal}
                >
                  <span aria-hidden='true'>✕</span>
                </button>
              </div>
            </div>
            {educationComplete && this.allLessonsComplete()
              ? this.renderCompletedView()
              : this.renderContentView()}
          </div>
        </div>
      </Modal>
    )
  }
}

EducationVideoModal.propTypes = {
  education: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    video_url: PropTypes.string,
    photo: PropTypes.string,
    education_sections: PropTypes.string,
    price: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    visibility: PropTypes.string,
  }),
  current_user: PropTypes.shape({
    type: PropTypes.string,
  }),
  selectedLesson: PropTypes.any,
  showModal: PropTypes.func,
  closeModal: PropTypes.func,
  closeCompleteModal: PropTypes.func,
  isCoach: PropTypes.bool,
  user_education: PropTypes.shape({
    id: PropTypes.string,
    completed_lessons: PropTypes.array,
    is_completed: PropTypes.bool,
  }),
  education_actions: PropTypes.shape({
    completeLesson: PropTypes.func,
    getUserEducation: PropTypes.func,
  }),
}

function mapStateToProps(state, _ownProps) {
  return {
    ui: state.ui,
    current_user: state.user.current_user,
    education: state.education,
    user_education: state.userEducation,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    common_actions: bindActionCreators(commonActions, dispatch),
    education_actions: bindActionCreators(educationActions, dispatch),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationVideoModal)
